import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'
import axios from '@axios'

export default function speakerService() {
  const fetchSpeaker = async (queryParams) => {
    const res = await axios.get('/speakers', { params: queryParams })

    return res.data
  }

  const sendSpeaker = async (form) => {
    const res = await axios.post('/speaker/create', form, {
      headers: { 'content-type': 'multipart/form-data' },
    })

    if (!res.data.response) {
      return JSON.parse(`{${res.data.substring(res.data.indexOf('{') + 1)}`)
    }

    return res.data
  }

  const updateEmailSpeaker = async (form) => {
    const res = await axios.post('speaker/update/user/email', form)

    return res.data
  }

  const updateSpeaker = async (form) => {
    const res = await axios.post('/speaker/update', form, {
      headers: { 'content-type': 'multipart/form-data' },
    })

    if (!res.data.response) {
      return JSON.parse(`{${res.data.substring(res.data.indexOf('{') + 1)}`)
    }

    return res.data
  }

  const deleteSpeaker = async (id) => {
    const res = await axios.post('speaker/delete', { id })

    return res.data
  }

  return {
    fetchSpeaker,
    sendSpeaker,
    updateEmailSpeaker,
    updateSpeaker,
    deleteSpeaker,
  }
}
